

.intro-container {
  font-family: "Inter", sans-serif;
  display: flex;
  flex-direction: column;
  text-align: start;
  align-items: start;
  margin-left: 10%;
  margin-bottom: 3%;
}

.name-banner-div {
  margin-bottom: 3%;
  display: flex;
  margin-left: 10%;
  flex-direction: row;
  align-items: last baseline;
}

.bullets {
  gap: 0.5vmax;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2vw;
}

.arrow {
  height: 1.2vmax;
}

.intro {
  font-size: 1.5vmax;

  font-weight: 200;
}
.home-buttons-div {
  margin-bottom: 3%;
}
.learn-more {
  white-space: nowrap;
  text-decoration: none;
  font-size: 1.5vmax;
  font-weight: 600;
  font-family: "Diderot";
  font-style: italic;
  background-image: linear-gradient(#e0adff, #b5f0ff);
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 0% 3px;
  transition: background-size 500ms ease-in-out;
}

.intro a {
  text-decoration: underline;
}

.browse-projects {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1.5vmax;
  font-weight: 500;
  align-content: center;
  color: #000; /* Black text color */
  background-color: transparent; /* White background */
  border: 1.5px solid #000; /* Black border */
  border-radius: 8px; /* Rounded corners */
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease;
  animation: sizeReverse 0.3s forwards;
}

.browse-projects:hover {
  background-color: black;
  color: white;
  border-color: #000;
  cursor: pointer;
  animation: sizeChange 0.3s forwards;
  transform: scale(1.1);
}

.browse-projects:hover .arrow-down g line {
  stroke: white;
  color: white;
}

.arrow-down {
  height: 1.2vw;
}

.star {
  margin-left: 10px;
  display: inline-block;
  height: 1.6vmax;
  transition: transform 0.3s ease;
}

.learn-more:hover {
  background-size: 100% 3px;
  color: inherit;
  cursor: pointer;
}

.star:hover {
  transform: rotate(360deg);
  cursor: pointer;
}
.browse-projects:active {
  background-color: black;
  color: white;
  border-color: #000;
}


@media (max-aspect-ratio: 13/12) and (min-width:500px){
  .browse-projects {
    padding: 10px 20px;
    font-size: 18px;
  }

  .learn-more {
    visibility: hidden;
  }

  .star {
    visibility: hidden;
  }
  .intro {
    font-size: 19px;
    font-weight: 200;
  }

  .arrow-down {
    height: 13px;
  }
  .name-banner-div {
    margin-bottom: 0;
  }
  .intro-container {
    margin-bottom: 7vh;
  }
}

@media (max-width:500px){
  .learn-more{
    visibility: hidden;
  }

  .star {
    visibility: hidden;
  }

  .browse-projects {
    padding: 7px 15px;
    font-size: 3vw;
  }

  .arrow-down {
    height: 2.5vw;
  }
  .intro {
    font-size: 13px;
    font-weight: 200;
  }

  .bullets {
  margin-bottom: 2vh;
}
}

@media (max-height:500px){

  .browse-projects {
    padding: 7px 15px;
    font-size: 13px;
  }

  .arrow-down {
    height: 11px;
  }
  .intro {
    font-size: 13px;
    font-weight: 200;
  }

  .bullets {
  margin-bottom: 2vh;
}
}

