.header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 2vh;
  background-color: #f9f9f9;
  z-index: 4;
  top: 0;
}

.logo {
  font-size: 3vmin;
  font-family: 'Diderot';
  font-weight: bold;
  left: 20px;
}

.nav {
  flex: 1;
  display: flex;
  justify-content: center;
}

.nav a {
  margin: 0 15px;
  font-size: 2vmin;
  color: #333;
  transition: all 0.3s ease;
}

.nav a:hover {
  font-weight: 700;
}

.nav a.active {
  font-weight: 700;
}


.scroll-down{
  margin-top: 2%;
  height: 3vw;
  transition: transform 0.3s ease, width 0.3s ease, height 0.3s ease;
}

.scroll-down:hover {
    transform: translateY(10px); /* Moves the button down */
    height: 3.1vw; /* Increases the height */
    cursor: pointer;
}


@media (max-width:500px) or (max-height:500px){
  .nav a {
  margin: 0 15px;
  font-size: 12px;
}

.logo {
  font-size: 13px;
}
}
