.name-banner{
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: start;
  align-items: center;
  height: 20vh;
  color: black;
}

.name-container {
  text-align: left;
  font-family:"Inter", sans-serif;
  flex-direction: row;
  display: flex;
  align-items:center;
  gap: 3vw;
}

.name {
  font-size: calc(4vw + 20px);
  font-weight: 700;
  font-family: 'Didot';
}

.title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.job {
  font-size: calc(1.2vw + 8px);
  font-weight: 200;
}

.location {
  font-size: calc(1.2vw + 8px);
  font-weight: 200;
  color: black;
  font-style: italic;
}
