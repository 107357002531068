.projects-page-container {
  display: flex;
  flex-direction: row;
}

.projects-container {
  display: flex;
  margin-left: 2vw;
  flex-wrap: wrap;
  height: 90vh;
  overflow-y: scroll;
  justify-content: start;
}

.card-container {
  margin: 1%;
  display: flex;
  background-color: #fdfdfd;
  border-radius: 20px;
  padding: 20px;
  flex-direction: column;
  width: 40%;
  transition: transform 0.2s ease-in-out;
  box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.1);
}

.card-container:hover {
  transform: scale(1.05);
  background-color: white;
  cursor: pointer;
}

.project-header {
  display: flex;
  margin-bottom: 10px;
}

.project-title {
  font-size: 0.95vw;
  align-self: flex-start;
  text-align: start;
  font-weight: 300;
}

.project-date {
  color: rgba(0, 0, 0, 0.424);
  font-size: 0.9vw;
  align-self: center;
  text-align: center;
  margin-left: 0.9vw;
  font-weight: 300;
}

.logo-title {
  height: 1.1em;
  text-align: center;
  align-self: center;
}

.project-description {
  font-size: 1vw;
  margin-top: 5px;
  font-weight: 200;
  text-align: start;
  margin-bottom: 20px;
}

.stack-section {
  font-size: 0.8vw;
  font-weight: 600;
  justify-content: center;
  align-self: bottom;
  margin-top: 3vh;
  align-items: center;
  display: flex;
  gap: 10px;
}

.stack-icon {
  height: 1.8vmax;
}

.profile-card {
  width: 100%;
  height: 90vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-picture {
  height: 15vmax;
  max-height: 200px;
  cursor: pointer;
  margin-bottom: 5%;
  transition: transform 0.2s ease-in-out;
}

.profile-picture:hover {
  transform: scale(0.9);
}

.profile-bio {
  margin-left: 10%;
  text-align: left;
  font-size: 0.95vw;
  align-self: flex-start;
}

.profile-education {
  font-size: 0.95vw;
  margin-bottom: 5%;
}
.profile-social-icons {
  margin-top: 5%;
  align-self: flex-start;
  margin-left: 10%;
}

.profile-social-icons img {
  height: 2.2vw;
  margin-right: 0.5vw;
}

.name-banner-profile {
  margin-top: 0;
  width: 100%;
  margin-left: 10%;
}

.name-banner-profile .name {
  font-size: 3vw;
  margin-top: 0;
}

.name-banner-profile .job {
  font-size: 0.7vw;
}

.name-banner-profile .location {
  font-size: 0.7vw;
}

.name-banner-profile .name-banner {
  margin-top: 2%;
  margin-bottom: 5%;
  height: fit-content;
}

.name-banner-profile .name-container {
  gap: 1%;
}
@media ((max-width: 1100px) and (max-aspect-ratio: 15/12)) or (max-height: 500px) {
  .projects-container {
    justify-content: center;
  }

  .profile-card {
    visibility: hidden;
    width: 0%;
    height: 0%; /* Full viewport height */
  }
  .card-container {
    margin: 0;
    margin-left: 0;
    background-color: #fdfdfd;
    margin-bottom: 5%;
    width: 80vw;
  }
  .project-title {
    font-size: 3vw;
  }

  .project-date {
    font-size: 2.5vw;
  }

  .project-description {
    font-size: 3vw;
  }

  .stack-section {
    font-size: 3vw;
  }

  .profile-social-icons img {
    margin-right: 0;
  }

  .stack-icon {
    height: 4vw;
    max-height: 100px;
  }
}
