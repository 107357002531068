* {
  margin: 0;
  padding: 0;

}

html, body, #root {
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: visible;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  font-family: "Inter", sans-serif;
  text-align: center;
  background: url('../assets/background.png') no-repeat center center fixed;
  background-repeat: repeat;
  background-size: cover;

}

main {
  flex: 1;
}


a {
  text-decoration: none;
  color: inherit;
}
