.footer {
  padding: 5px;
  width: 100%;
  text-align: left;
  position: fixed;
  left: 0;
  bottom: 0;
  font-size: 0.8vmax;
  background-color: #f9f9f9;
;
}

.social-links {
  margin-top: 10px;
}

.social-links a {
  margin: 0 10px;
  display: inline-block;
}

.social-links img {
  width: 1.2vw;
  min-width: 20px;
}
