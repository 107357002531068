.title-container {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-left: 2%;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.title-stack-container {
  margin: 2vh;
  display: flex;
  justify-content: center;
  background-color: #fdfdfd;
  border-radius: 20px;
  padding: 20px;
  flex-direction: column;

  box-shadow: 5px 0px 20px rgba(0, 0, 0, 0.1);

  width: 50vw;
}

.tech-stack-container {
  justify-content: center;
  align-items: center;
  display: flex;
  
  margin-top: 3vw;
}
.stack-grid {
  margin-left: 10%;
  margin-right: 10%;
  gap: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
}

.stack-title {
  margin-top: 2%;
  font-size: 1.2vw;
  font-weight: 600;
  margin-bottom: 1%;
}

.pl {
  justify-content: center;
  display: flex;
  margin-bottom: 1%;
  font-size: 1vw;
  font-weight: 200;
  font-family: "Inter";
}

.stack-grid p a img {
  height: 3vw;
  max-height: 80px;
}

.stack-grid a img {
  height: 3vw;
  width: auto;
  max-height: 80px;
}


@media ((max-width: 1100px) and (max-aspect-ratio: 15/12)) or (max-height:500px){
  .pl {
  font-size: 4vw;
}

.stack-grid p a img {
  height: 4vw;
}

.stack-grid a img {
  height: 5vw;
}

.title-stack-container {
  min-width: 60vw;
  margin: 2vh;
  display: flex;
}

}